import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Container from "../components/container";
import styles from "./demo-crm.module.css";
import SEO from "../components/seo";
import DemoCreator from "../components/demoCreator";
import Columns from "../components/columns";
import MultiStepFormButton from "../components/MultiStepForm/MultiStepFormButton";

const DemoCRM = () => {
    return (
      <Layout>
        <SEO
          title="Demo CRM - Wypróbuj prosty system CRM za darmo!"
          description="Demo CRM • Szanse sprzedaży, raporty, dynamiczne pulpity, baza wiedzy o kontrahentach, zarządzanie uprawnieniami • Prosty CRM - wypróbuj za darmo QuickCRM"
          keywords={["demo crm"]}
        />
        <Hero maxHeight="280px" justifyContent="center">
          <h1>Wypróbuj bezpłatnie przez 30 dni</h1>
          <p>Sprawdź, jak wiele możliwości daje prosty CRM</p>
          {/* <SystemCreator /> */}
        </Hero>
        <Container>
          <Columns>
          <div>
          <p>
              <h3>Co zyskasz, decydując się na QuickCRM?</h3>
              <li>Narzędzie do monitorowania relacji z klientem</li>
              <li>Dowiesz się jak zaprojektować proces sprzedaży</li>
              <li>Będziesz w stanie szybko mierzyć skuteczność handlowców</li>
              <li>Uporządkujesz bazę klientów</li>
              <li>Zaplanujesz pracę swojego zespołu</li>
              <li>
                Dowiesz się, na jakim etapie tracisz najwięcej szans sprzedaży
              </li>
              </p>
       </div>
       <div>
              <p>
          W tym czasie sprawdzisz możliwości systemu i przekonasz się,
              jak może on zmienić sposób pracy Twojego działu handlowego. Do
              niczego się nie zobowiązujesz – dajemy Ci czas na przetestowanie
              systemu. Po ich upłynięciu możesz sam zdecydować, czy chcesz
              kontynuować pracę z Quick CRM –
              <strong> nawet za 4 zł miesięcznie! </strong>
            </p>
            </div>
            </Columns>
    <p
        style={{
          textAlign: "center"
        }}
      ><MultiStepFormButton></MultiStepFormButton></p>
          </Container>
          <Container>
          <br />
          <div className={styles.blockWrappper}>
            <div>
              <h3>Jak przedłużyć subskrypcję?</h3>
              <div>
                <p>
                  Przekształć demo w pełni działający system. Wybierz informacje
                  o zakupie pełnej wersji i postępuj zgodnie ze wskazówkami.
                </p>
              </div>
            </div>
            <div>
              <div className={styles.videoResponsive}>
                <iframe
                  title="instrukcja 2"
                  src="https://player.vimeo.com/video/323682168"
                  frameBorder="0"
                  allowFullScreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                />
              </div>
            </div>
          </div>
        </Container>
      </Layout>
    );
}

export default DemoCRM;